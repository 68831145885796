import * as React from 'react';
import {chunk, toInteger} from 'lodash';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import AppConfig from '../../Constans';
import _ from 'lodash';
import { IRecurrenceProps, IRecurrenceState } from "./IRecurrence";
import FLatDatePickr from '../utils/FLatDatePickr';
import { act } from 'react-dom/test-utils';

const moment =  require('moment');
const uuidv4 = require('uuid/v4');

export default class Recurrence extends React.Component<IRecurrenceProps,IRecurrenceState> {
    constructor(props:IRecurrenceProps) {
        super(props);
        this.state = {
            recurrenceType: "none",
            recurrenceDays: [],
            recurrenceEndDate: new Date(props.minEndDate),
        };
    }
    componentDidUpdate(prevProps: IRecurrenceProps) {
        if (this.props.minEndDate !== prevProps.minEndDate) {
            const _recurrenceEndDate = new Date(this.state.recurrenceEndDate);
            this.setState({
                recurrenceEndDate: _recurrenceEndDate > this.props.minEndDate ? _recurrenceEndDate : new Date(this.props.minEndDate)
            }, () => {
                this._getActivityDates();
            });
        }
    }
    public render(): React.ReactElement {              
        const {recurrenceType, recurrenceDays, recurrenceEndDate} = this.state;   
        const {minEndDate} = this.props;
        return <div>
            <div className="ms-grid" style={{width: "100%"}}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <Dropdown
                            label="Select Recurrence Type"                            
                            selectedKey={recurrenceType}
                            onChange={this._onRecTypeChange}
                            options={[
                                {key: "none", text: "None"}, 
                                {key: "everyday", text: "Everyday"},
                                {key: "everyweekday", text: "Every Weekday"},
                                {key: "weekly", text: "Weekly"},
                            ]}                            
                        ></Dropdown>
                    </div>
                </div>
                {
                    recurrenceType==="weekly" ? 
                        <div className="ms-Grid-row" style={{paddingTop: "5px"}}>
                            <div className="ms-Grid-col ms-sm12">
                                <Dropdown
                                    label="Select Weekdays"   
                                    required
                                    multiSelect={true} 
                                    selectedKeys={recurrenceDays}  
                                    onChange={this._onRecDaysChange}                  
                                    options={[
                                        {key: 0, text: "Sunday"}, 
                                        {key: 1, text: "Monday"},
                                        {key: 2, text: "Tuesday"}, 
                                        {key: 3, text: "Wednesday"},
                                        {key: 4, text: "Thursday"}, 
                                        {key: 5, text: "Friday"},
                                        {key: 6, text: "Saturday"}
                                    ]}
                                ></Dropdown>
                            </div>                    
                        </div> : ""
                }
                {
                    recurrenceType!=="none" ? 
                        <div className="ms-Grid-row" style={{paddingTop: "5px"}}>
                            <div className="ms-Grid-col ms-sm12">
                                <FLatDatePickr                             
                                    viewForm={false}                                                                 
                                    required={true}                                                         
                                    showNonFormLabel={false} 
                                    labelHead="Select Recurrence End Date" 
                                    minDate={this.props.minEndDate}
                                    maxDate={this.props.maxEndDate}
                                    dateInput={recurrenceEndDate} 
                                    updateFunc={this._onChangeRecEndDate}
                                ></FLatDatePickr>
                            </div>                    
                        </div> : ""
                } 
            </div>
        </div>;
    }
    _onRecTypeChange=(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number)=>{
        if(option){
          this.setState({recurrenceType: option.key.toString()}, ()=>{
            this._getActivityDates();
          });
        }
    };
    _onRecDaysChange=(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number)=>{
        if(option){
            const {recurrenceDays} = this.state;
            const ind = recurrenceDays.indexOf(toInteger(option.key));
            if(ind<0){
                recurrenceDays.push(toInteger(option.key));
            }
            else{
                recurrenceDays.splice(ind, 1);
            }
            this.setState({recurrenceDays: recurrenceDays}, ()=>{
                this._getActivityDates();
            });
        }   
    };
    _onChangeRecEndDate = (endDate:any) => {    
        if (endDate){
            this.setState({recurrenceEndDate: endDate as Date}, ()=>{
                this._getActivityDates();
            });
        }
    };
    _getActivityDates = () => {
        let actDates: Date[] = [];
        const {recurrenceType, recurrenceDays, recurrenceEndDate} = this.state;
        const {minEndDate} = this.props;

        switch(recurrenceType){
            case "everyday": 
            {
                actDates = [...this._getDatesBetween(minEndDate, recurrenceEndDate)];
                break;
            }
            case "everyweekday": 
            {
                actDates = [...this._getDatesBetween(minEndDate, recurrenceEndDate, true)];
                break;
            }
            case "weekly": 
            {
                actDates = [...this._getDatesBetween(minEndDate, recurrenceEndDate, false, recurrenceDays)];
                break;
            }
            default:
            {
                break;
            }
        }
        if(recurrenceType!=="none" && actDates.length===0){
            this.props.onError("Invalid recurrence settings");
        }
        else{
            this.props.onRecurring(actDates);
        }
    }
    _getDatesBetween=(startDate: Date, endDate: Date, onlyWeekdays?: boolean, weekdayArr?: number[]): Date[] => {
        const dates: Date[] = [];
        let currDate = startDate;
        while(currDate <= endDate){
            if(onlyWeekdays){
                if(currDate.getDay()>0 && currDate.getDay()<6){
                    dates.push(new Date(currDate));
                }
            }
            else if(weekdayArr){                
                if(_.findIndex(weekdayArr, d => {return d===currDate.getDay()} )>=0){
                    dates.push(new Date(currDate));
                }
            }
            else{
                dates.push(new Date(currDate));
            }            
            currDate = new Date(moment(currDate).add(1, "days"));
        }
        return dates;
    }
}