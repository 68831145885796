import { axiosPostCall } from './AxiosService';

let ActivityGridService = {
    getActivitiesForUser(userEmail, statusId, experimentId) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivityGrid/GetActivityGridForUser', {
                    "UserEmail": userEmail,
                    "StatusId": statusId,
                    "ExperimentId": experimentId
                })
                .then(activityGridData => {
                    resolve(activityGridData);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    deleteActivities(jsonOnj){
        console.log(jsonOnj);
        return new Promise((resolve, reject) => {
            axiosPostCall(`/api/ActivityGrid/DeleteMultiple`,jsonOnj)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    GetExpWithoutActivitiesGridForUser(userEmail, statusId, experimentId) {
        return new Promise((resolve, reject) => {
            axiosPostCall('/api/ActivityGrid/GetExperimentGridForUser', {
                    "UserEmail": userEmail,
                    "StatusId": statusId,
                    "ExperimentId": experimentId
                })
                .then(activityGridData => {
                    resolve(activityGridData);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },
};
export default ActivityGridService;