import * as axios from 'axios';
import CacheManager from './CacheManager';
function getToken() {
    try {
        let isCache = CacheManager.isCacheExpired('accountInfo');
        if(isCache){
            const cacheAccountInfo = CacheManager.getCacheItem('accountInfo');
            let accountInfo = JSON.parse(cacheAccountInfo.value);
            return accountInfo.jwtIdToken;
        }
        else{
            //get token code
        }
    } catch (err) {
        console.log(err);        
    }
    return " ";
}
function getHeaders(){
    let token = getToken();
        let head = {
                headers: {
                    authorization: "Bearer " +token,
                }
            };
        return head;
}   
export function axiosPostCall(url, body,timeoutMillSec){
    return new Promise((resolve, reject) => {
        let tokenHeader = getHeaders();
        if(timeoutMillSec){
            axios.post(url, body, tokenHeader,{timeout: timeoutMillSec})
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
        }else{
            axios.post(url, body, tokenHeader)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error.response);
                reject(error.response);
            });
        }
    });
}
export function axiosGetCall(url){
    return new Promise((resolve, reject) => {
        let tokenHeader = getHeaders();
        axios.get(url, tokenHeader)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error.response);
                reject(error.response);
            });
    });
}
export function axiosPutCall(url,body){
    return new Promise((resolve, reject) => {
        let tokenHeader = getHeaders();
        axios.put(url, body, tokenHeader)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error.response);
                reject(error.response);
            });
    });
}